export const ADMIN = {
    "idAuthority": 1,
    "name": "ADMINISTRADOR",
    "description": "Administrador",
    "urlLogIn": "/app/pacientes",
    "enabled": true
}
export const SYSTEM = {
    "idAuthority": 2,
    "name": "SYSTEM",
    "description": "Sistemas de integración",
    "urlLogIn": "/app/pacientes",
    "enabled": true
}
export const INVENTORY = {
    "idAuthority": 3,
    "name": "INVENTORY",
    "description": "Administrador de inventarios",
    "urlLogIn": "/app/inventarios",
    "enabled": true
}
export const USER = {
    "idAuthority": 4,
    "name": "USER",
    "description": "Usuarios",
    "urlLogIn": "/app/pacientes",
    "enabled": true
}
export const FARMACIA = {
    "idAuthority": 5,
    "name": "FARMACIA",
    "description": "Administración de farmacía",
    "urlLogIn": "/app/inventarios",
    "enabled": true
}
export const MEDICO = {
    "idAuthority": 6,
    "name": "MEDICO",
    "description": "Personal médico",
    "urlLogIn": "/app/agenda",
    "enabled": true
}
export const ASISTENTE_MEDICO = {
    "idAuthority": 7,
    "name": "ASISTENTE MEDICO",
    "description": "Personal de asistencia médica",
    "urlLogIn": "/app/agenda",
    "enabled": true
}

export const PERMISSON = [
    ADMIN,
    FARMACIA,
    MEDICO,
    ASISTENTE_MEDICO
]